<template>
  <v-list-item
    class="document-item rounded mb-2"
    :class="{ 'grey darken-4': $vuetify.theme.dark }"
    :key="item.documentId"
    @click="getAsset"
  >
    <v-icon class="mr-3" large color="gray lighten-1">{{ mimeIcon(item.mimeType) }}</v-icon>
    <v-list-item-content>
      <v-list-item-subtitle class="text-caption text-uppercase">
        {{ mimeFriendly(item.mimeType) }}
      </v-list-item-subtitle>

      <v-list-item-title v-html="item.name"></v-list-item-title>
      <v-list-item-subtitle>
        {{ item.description }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import Helpers from "@/mixins/helpers";
export default {
  name: "DocumentAssetListItem",
  mixins: [Helpers],
  props: {
    item: {
      type: Object,
    },
    parent: {
      type: String,
    },
  },
  methods: {
    getAsset(document) {
      this.$emit("getAsset", document);
    },
    mimeFriendly(mimeType) {
      if (mimeType.includes("video")) {
        mimeType = "video";
      }
      if (mimeType.includes("image")) {
        mimeType = "image";
      }
      if (mimeType.includes("audio")) {
        mimeType = "audio";
      }
      switch (mimeType) {
        case "application/pdf":
          return this.$t("c_documentassetlistitem.t_pdf");
        case "video":
          return this.$t("c_documentassetlistitem.t_video");
        case "image":
          return this.$t("c_documentassetlistitem.t_image");
        case "audio":
          return this.$t("c_documentassetlistitem.t_audio");
        case "application/json":
          return this.$t("c_documentassetlistitem.t_link");
        default:
          return this.$t("c_documentassetlistitem.t_file");
      }
    },
    mimeIcon(mimeType) {
      if (mimeType.includes("video")) {
        mimeType = "video";
      }
      if (mimeType.includes("image")) {
        mimeType = "image";
      }
      if (mimeType.includes("audio")) {
        mimeType = "audio";
      }
      switch (mimeType) {
        case "application/pdf":
          return "mdi-file-pdf-box";
        case "video":
          return "mdi-video-box";
        case "image":
          return "mdi-image";
        case "audio":
          return "mdi-volue-high";
        case "application/json":
          return "mdi-link-box-variant";
        default:
          return "mdi-open-in-new";
      }
    },
  },
};
</script>
