<template>
  <div class="document bg-main">
    <v-container fluid class="px-0">
      <v-alert
        v-if="!$online"
        dense
        tile
        color="gray"
        icon="mdi-alert-circle-outline"
        class="alert-offline text-headline"
      >
        {{ $t("t_no_connection") }}
      </v-alert>
      <v-row>
        <v-col class="px-2 mx-2">
          <v-card class="vh-100 bg-main" flat>
            <v-toolbar flat class="transparent px-0 py-2 pt-1 bar-top" height="30">
              <v-toolbar-title class="px-0">
                <v-btn
                  v-if="prevRoute && prevRoute.name"
                  :to="{ name: prevRoute.name }"
                  class="btn-secondary mx-0 mt-0"
                  small
                  ><v-icon>mdi-chevron-left</v-icon> {{ $t("t_back") }}</v-btn
                >
                <v-btn v-else @click="$router.go(-1)" class="btn-secondary mx-0 mt-0" small
                  ><v-icon>mdi-chevron-left</v-icon> {{ $t("t_back") }}</v-btn
                >
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <div class="py-1" v-if="document">
              <v-card-title class="text-h5 px-3">{{ document.name }}</v-card-title>
              <v-card-subtitle class="text-subtitle-1 px-3" v-if="document.description">{{
                document.description
              }}</v-card-subtitle>
              <v-card-text class="px-1">
                <v-list
                  flat
                  v-if="documentAssets && documentAssets.length"
                  :class="{ transparent: $vuetify.theme.dark }"
                >
                  <div v-for="(item, index) in documentAssets" :key="'document-' + index">
                    <DocumentAssetListItem
                      ref="documentAssetListItem"
                      :item="item"
                      :parent="'document'"
                      @getAsset="downloadAsset(item)"
                    />
                  </div>
                </v-list>
              </v-card-text>
            </div>
            <h4 class="pa-3 mt-5" v-if="!loading && !document">{{ $t("v_document.t_no_document") }}</h4>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Helpers from "@/mixins/helpers";
import DocumentAssetListItem from "@/components/documents/DocumentAssetListItem.vue";
import { DocumentService } from "@/services";

export default {
  name: "Document",
  metaInfo() {
    return {
      title: this.document?.name,
    };
  },
  mixins: [Helpers],
  components: { DocumentAssetListItem },
  data() {
    return {
      loading: false,
      document: null,
      documentAssets: null,
      prevRoute: null,
      activeDocument: null,
    };
  },
  methods: {
    async downloadAsset(documentAsset) {
      this.showLoadBar();
      let params = {};
      params.documentAssetId = documentAsset.id;
      try {
        const asset = await DocumentService.downloadAsset(params);
        this.activeDocument = asset.data;
        if (documentAsset.mimeType == "application/json") {
          fetch(this.activeDocument.downloadUrl)
            .then((response) => response.json())
            .then((data) => {
              const url = data.url;
              if (this.$platform !== "web") {
                window.location = url;
              } else {
                window.open(url, "_blank");
              }
            });
        } else if (this.activeDocument.downloadUrl) {
          if (this.$platform !== "web") {
            window.location = this.activeDocument.downloadUrl;
          } else {
            const blob = await this.getAssetFromDownloadUrl(this.activeDocument.downloadUrl, null, false);
            window.open(blob, "_blank");
          }
        }
        this.hideLoadBar();
      } catch {
        this.hideLoadBar();
      }
    },
    customSort(array, p, s) {
      return array.sort((a, b) => {
        // Check primary property
        if (!a[p] && b[p]) return 1;
        if (a[p] && !b[p]) return -1;
        if (a[p] && b[p]) {
          const primaryComparison = a[p].localeCompare(b[p]);
          if (primaryComparison !== 0) return primaryComparison;
        }
        // Check secondary property when primary is equal or both null
        if (!a[s] && b[s]) return 1;
        if (a[s] && !b[s]) return -1;
        if (a[s] && b[s]) {
          return a[s].localeCompare(b[s]);
        }
        return 0;
      });
    },
    async init() {
      this.loading = true;
      let params = this.$route.params;
      this.showLoadBar();
      try {
        const doc = await DocumentService.getDocument(params.id);
        this.document = doc.data;
        const r = await DocumentService.getDocumentAssets(params.id);
        let assets = r.data;
        this.documentAssets = this.customSort(assets, "description", "name");
        this.loading = false;
        this.hideLoadBar();
      } catch {
        this.loading = false;
        this.hideLoadBar();
      }
    },
  },
  mounted() {
    if (this.$online) {
      this.init();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>
<style lang="scss">
.bar-top > div {
  padding-left: 10px;
}
</style>
